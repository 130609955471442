import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';

// Services
import addressService from '@/services/crm/addressService.js';
import distributorService from '@/services/statistics/distributorService.js';
import currencyFormatService from '@/services/formatters/currencyFormatService.js';

// Components

export default {
  components: {},
  data: () => ({
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: []
    },
    sm2Data: [],
    sm2DataComplete: [],
    articleDetailsValues: [],
    articleDetailsAmounts: [],
    article: {
      nr: '',
      description: ''
    },
    // features: {},
    summaryLoading: true,
    search: '',
    sortDesc: false,
    editorContent: '',
    headers: [{
      text: 'Ausführung',
      align: 'start',
      value: 'detail'
    }, {
      text: 'Anfangsbestand',
      value: 'opening',
      align: 'end'
    }, {
      text: 'Einkäufe',
      value: 'purchases',
      align: 'end'
    }, {
      text: 'Verkäufe',
      value: 'sales',
      align: 'end'
    }, {
      text: 'Inventur',
      value: 'inventory',
      align: 'end'
    }, {
      text: 'Materialverbrauch',
      value: 'materialsInput',
      align: 'end'
    }, {
      text: 'Endbestand',
      value: 'finalAmount',
      align: 'end'
    }],
    headers2: [{
      text: 'Ausführung',
      value: 'sm',
      align: 'start'
    }, {
      text: 'Anfangsbestand',
      value: 'ab',
      align: 'start'
    }, {
      text: 'Einkäufe',
      value: 'ek',
      align: 'end'
    }, {
      text: 'Verkäufe',
      value: 'vk',
      align: 'end'
    }, {
      text: 'Inventur',
      value: 'iv',
      align: 'end'
    }, {
      text: 'Materialverbrauch',
      value: 'mt',
      align: 'end'
    }, {
      text: 'Endbestand',
      value: 'eb',
      align: 'end'
    }],
    headers3: [{
      text: '',
      value: 'default'
    }],
    itemsCompleteValue: [{
      default: 'Anfangsbestand'
    }, {
      default: 'Einkäufe'
    }, {
      default: 'Verkäufe'
    }, {
      default: 'Inventur'
    }, {
      default: 'Materialverbrauch'
    }, {
      default: 'Endbestand'
    }],
    itemsCompleteAmount: [{
      default: 'Anfangsbestand'
    }, {
      default: 'Einkäufe'
    }, {
      default: 'Verkäufe'
    }, {
      default: 'Inventur'
    }, {
      default: 'Materialverbrauch'
    }, {
      default: 'Endbestand'
    }],
    newDataValue: [],
    newDataAmount: [],
    detailsLoaded: false
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    artNr: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  methods: {
    init() {
      this.detailsLoaded = false;
      addressService.getAddress(this.lfdnr).then(response => {
        this.address = response.data;
      });
      this.loadArticleDetailed();
    },
    openArticleType() {
      // statisticsDistributorArticleCategories
      /*this.$router.push({
        name: 'statisticsDistributorArticleCategories',
        query: {
          from: this.filterFromDate,
          to: this.filterToDate
        },
        params: { lfdnr: this.lfdnr, articleType: item.code }
      })*/
    },
    loadArticleDetailed() {
      this.summaryLoading = true;
      distributorService.getArticleDetailed(this.lfdnr, this.artNr, this.filterFromDate, this.filterToDate).then(response => {
        this.headers3 = [{
          text: '',
          value: 'default'
        }];
        this.itemsCompleteAmount = [{
          default: 'Anfangsbestand'
        }, {
          default: 'Einkäufe'
        }, {
          default: 'Verkäufe'
        }, {
          default: 'Inventur'
        }, {
          default: 'Materialverbrauch'
        }, {
          default: 'Endbestand'
        }];
        this.itemsCompleteValue = [{
          default: 'Anfangsbestand'
        }, {
          default: 'Einkäufe'
        }, {
          default: 'Verkäufe'
        }, {
          default: 'Inventur'
        }, {
          default: 'Materialverbrauch'
        }, {
          default: 'Endbestand'
        }];
        this.newDataAmount = [];
        this.newDataValue = [];
        this.sm2Data = response.data;
        console.warn(response.data);
        response.data.forEach(entry => {
          if (entry.combination) {
            this.sm2DataComplete = entry;
            entry.data.forEach(entry => {
              this.headers3.push({
                text: entry.sm,
                value: entry.sm
              });
              if (entry.abValue !== 0) {
                this.itemsCompleteValue[0][entry.sm] = currencyFormatService.formatEuro(entry.abValue);
              }
              if (entry.ab !== 0) {
                this.itemsCompleteAmount[0][entry.sm] = entry.ab + ' ' + entry.unit;
              }
              if (entry.ekValue !== 0) {
                this.itemsCompleteValue[1][entry.sm] = currencyFormatService.formatEuro(entry.ekValue);
              }
              if (entry.ek !== 0) {
                this.itemsCompleteAmount[1][entry.sm] = entry.ek + ' ' + entry.unit;
              }
              if (entry.vkValue !== 0) {
                this.itemsCompleteValue[2][entry.sm] = currencyFormatService.formatEuro(entry.vkValue);
              }
              if (entry.vk !== 0) {
                this.itemsCompleteAmount[2][entry.sm] = entry.vk + ' ' + entry.unit;
              }
              if (entry.ivValue !== 0) {
                this.itemsCompleteValue[3][entry.sm] = currencyFormatService.formatEuro(entry.ivValue);
              }
              if (entry.iv !== 0) [this.itemsCompleteAmount[3][entry.sm] = entry.iv + ' ' + entry.unit];
              if (entry.mtValue !== 0) {
                this.itemsCompleteValue[4][entry.sm] = currencyFormatService.formatEuro(entry.mtValue);
              }
              if (entry.mt !== 0) {
                this.itemsCompleteAmount[4][entry.sm] = entry.mt + ' ' + entry.unit;
              }
              if (entry.ebValue !== 0) {
                this.itemsCompleteValue[5][entry.sm] = currencyFormatService.formatEuro(entry.ebValue);
              }
              if (entry.eb !== 0) {
                this.itemsCompleteAmount[5][entry.sm] = entry.eb + ' ' + entry.unit;
              }
            });
          }
        });
        this.summaryLoading = false;
        this.newDataValue = [];
        this.sm2Data.forEach(data => {
          var newData = {};
          var newDataAmount = {};
          newData.data = [{
            default: 'Anfangsbestand'
          }, {
            default: 'Einkäufe'
          }, {
            default: 'Verkäufe'
          }, {
            default: 'Inventur'
          }, {
            default: 'Materialverbrauch'
          }, {
            default: 'Endbestand'
          }];
          newData.sm2 = data.sm2;
          newDataAmount.data = [{
            default: 'Anfangsbestand'
          }, {
            default: 'Einkäufe'
          }, {
            default: 'Verkäufe'
          }, {
            default: 'Inventur'
          }, {
            default: 'Materialverbrauch'
          }, {
            default: 'Endbestand'
          }];
          newDataAmount.sm2 = data.sm2;
          data.data.forEach(entry => {
            if (entry.abValue !== 0) {
              newData.data[0][entry.sm] = currencyFormatService.formatEuro(entry.abValue);
              newDataAmount.data[0][entry.sm] = entry.ab + ' ' + entry.unit;
            }
            if (entry.ekValue !== 0) {
              newData.data[1][entry.sm] = currencyFormatService.formatEuro(entry.ekValue);
              newDataAmount.data[1][entry.sm] = entry.ek + ' ' + entry.unit;
            }
            if (entry.vkValue !== 0) {
              newData.data[2][entry.sm] = currencyFormatService.formatEuro(entry.vkValue);
              newDataAmount.data[2][entry.sm] = entry.vk + ' ' + entry.unit;
            }
            if (entry.ivValue !== 0) {
              newData.data[3][entry.sm] = currencyFormatService.formatEuro(entry.ivValue);
              newDataAmount.data[3][entry.sm] = entry.iv + ' ' + entry.unit;
            }
            if (entry.mtValue !== 0) {
              newData.data[4][entry.sm] = currencyFormatService.formatEuro(entry.mtValue);
              newDataAmount.data[4][entry.sm] = entry.mt + ' ' + entry.unit;
            }
            if (entry.ebValue !== 0) {
              newData.data[5][entry.sm] = currencyFormatService.formatEuro(entry.ebValue);
              newDataAmount.data[5][entry.sm] = entry.eb + ' ' + entry.unit;
            }
          });
          if (data.sum.ab === 0 && data.sum.ek === 0 && data.sum.vk === 0 && data.sum.iv === 0 && data.sum.mt === 0 && data.sum.eb === 0) {
            newData.isEmpty = true;
            newDataAmount.isEmpty = true;
          } else {
            newData.isEmpty = false;
            newDataAmount.isEmpty = false;
          }
          this.newDataValue.push(newData);
          this.newDataAmount.push(newDataAmount);
        });
        console.warn(this.newDataAmount);
        this.detailsLoaded = true;
      });

      // distributorService
      //   .getArticleDetailedFeatures(
      //     this.lfdnr,
      //     this.artNr,
      //     this.filterFromDate,
      //     this.filterToDate
      //   )
      //   .then(response => {
      //     this.features = response.data
      //     this.summaryLoading = false
      //   })
    },
    filterZeroLines(models) {
      const filteredDetails = [];
      models.forEach(detail => {
        if (false == (detail.opening === 0 && detail.purchases === 0 && detail.sales === 0 && detail.inventory === 0 && detail.materialsInput === 0 && detail.finalAmount === 0)) filteredDetails.push(detail);
      });
      return filteredDetails;
    },
    formatPiecesOrZero(value) {
      if (value == null || value == '') return '';
      if (this.showAmount === true) return value == 0 ? '' : value + ' Stk.';else return value == 0 ? '' : currencyFormatService.formatEuro(value);
    }
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate,
      showAmount: state => state.distributors.showAmount
    }),
    showAmountLocal: {
      get() {
        return this.showAmount;
      },
      set(value) {
        this.$store.dispatch('setShowAmount', value);
      }
    },
    showTotalLine() {
      var a = this.article;
      if (a == null || a.nr == '' && a.description == '') return false;
      if (this.showAmount === true) return false == (a.sumOpeningAmount === 0 && a.sumPurchasesAmount === 0 && a.sumSalesAmount === 0 && a.sumInventoryAmount === 0 && a.sumMaterialsInputAmount === 0 && a.sumFinalAmount === 0);else return false == (a.sumOpeningValue === 0 && a.sumPurchasesValue === 0 && a.sumSalesValue === 0 && a.sumInventoryValue === 0 && a.sumMaterialsInputValue === 0 && a.sumFinalValue === 0);
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    filterFromDate: function () {
      this.loadArticleDetailed();
    },
    filterToDate: function () {
      this.loadArticleDetailed();
    },
    artNr: function () {
      this.init();
    }
  }
};